import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { Breadcrumb, Button, Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCreateTopicMutation } from "../../../redux/api";
import QuestionForm from "../QuestionForm";
import "../Topic.css";
import TopicForm from "../TopicForm"; // Renamed from VideoForm
import validationSchema from "../validationSchema";
import { v4 as uuid } from "uuid";
import useStorageUpload from "../../../shared/hooks/useStorageUpload.hook";

const NewTopic = () => {
  const navigate = useNavigate();
  const [createTopic, { isLoading, data: createdTopicLazyResponse }] =
    useCreateTopicMutation();
  const { uploadFileToStorage, isLoading: isUploadingFile } =
    useStorageUpload();
  let { courseId, chapterId } = useParams();

  const handleSubmitTopic = async () => {
    try {
      const formData = new FormData();
      formData.append("chapter", chapterId);
      formData.append("title", formik.values.title);
      formData.append("hidden", formik.values.hidden);
      formData.append("description", formik.values.description);
      const videoUuid = uuid();
      await uploadFileToStorage(
        new File([formik.values.videoFile], `videos/${videoUuid}`, {
          type: formik.values.videoFile.type,
        }),
      );
      formData.append("video_file", `videos/${videoUuid}`);
      formik.values.questions?.forEach((question, questionIndex) => {
        formData.append(
          `questions[${questionIndex}].topic`,
          createdTopicLazyResponse.id,
        );
        formData.append(
          `questions[${questionIndex}].question_text`,
          question.question_text,
        );
        question.answers.forEach((answer, answerIndex) => {
          formData.append(
            `questions[${questionIndex}].answers[${answerIndex}].answer_text`,
            answer.answer_text,
          );
          formData.append(
            `questions[${questionIndex}].answers[${answerIndex}].is_correct`,
            answer.is_correct,
          );
        });
      });
      const createdTopic = await createTopic(formData).unwrap();
      return createdTopic;
    } catch (err) {
      console.error("Failed to create topic:", err);
      toast.error("Something went wrong.");
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      videoFile: null,
      questions: [],
      hidden: false,
    },
    validationSchema,
    onSubmit: handleSubmitTopic,
  });

  const getSaveButtonText = () => {
    if (isUploadingFile) {
      return "Uploading video...";
    }

    return "Save";
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => navigate(`/app/course/${courseId}/edit`)}
        >
          Course
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() =>
            navigate(`/app/course/${courseId}/chapter/${chapterId}/edit`)
          }
        >
          Chapter
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Topic</Breadcrumb.Item>
      </Breadcrumb>

      <Row className="w-100 mb-5">
        <Col sm={12}>
          <TopicForm formik={formik} />
          <div className="form-container mt-3">
            <QuestionForm topicFormik={formik} />
          </div>
          <div className="form-container d-flex justify-content-between mt-3">
            <Button
              variant="light"
              as={Link}
              to={`/app/course/${courseId}/chapter/${chapterId}/edit`}
            >
              <FontAwesomeIcon icon={faChevronLeft} /> Back
            </Button>
            <Button
              type="button"
              onClick={() => {
                formik.submitForm().then((createdTopic) => {
                  if (createdTopic) {
                    navigate(
                      `/app/course/${courseId}/chapter/${chapterId}/topic/${createdTopic.id}/edit`,
                    );
                    toast.success("Topic added successfully!");
                  }
                });
              }}
              disabled={isLoading || isUploadingFile}
            >
              {getSaveButtonText()}
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NewTopic;
