import JoditEditor from "jodit-react";
import { useCallback, useMemo, useRef, useState } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import parse from "html-react-parser";
import { useGenerateCourseDescriptionMutation } from "../../redux/api";
import Dialog from "../../shared/components/Dialog";
import GenerateCourseDescriptionForm from "./GenerateCourseDescriptionForm";
import generateCourseDescriptionFormValidationSchema from "./generateCourseDescriptionFormValidationSchema";
import { useFormik } from "formik";
import toast from "react-hot-toast";

const CourseForm = ({ hideHeading, formik, isEdit = false }) => {
  const [coverUrl, setCoverUrl] = useState();
  const [edittingDescription, setEdittingDescription] = useState(false);
  const [
    generateCourseDescription,
    { isLoading: isGenerateCourseDescriptionLoading },
  ] = useGenerateCourseDescriptionMutation();
  const [
    showGenerateCourseDescriptionDialog,
    setShowGenerateCourseDescriptionDialog,
  ] = useState(false);
  const editor = useRef();

  const options = useMemo(
    () => [
      "bold",
      "italic",
      "|",
      {
        name: "paragraph",
        list: {
          h2: "Heading 2",
          h3: "Heading 3",
          h4: "Heading 4",
          p: "Normal",
        },
      },
      {
        name: "codeBlock",
        iconURL: "https://cdn-icons-png.flaticon.com/512/149/149147.png", // You can replace with a better icon URL
        exec: function (editor) {
          editor.selection.insertHTML(
            "<pre class='text-bg-light'><code></code></pre>",
          );
        },
        tooltip: "Insert Code Block",
      },
      "|",
      "ul",
      "ol",
      "|",
      "|",
      "outdent",
      "indent",
      "align",
      "|",
      "hr",
      "|",
      "fullsize",
      "|",
      "table",
      "link",
      "|",
      "undo",
      "redo",
    ],
    [],
  );
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "",
      defaultActionOnPaste: "insert_as_html",
      defaultLineHeight: 1.5,
      enter: "div",
      // options that we defined in above step.
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      toolbarAdaptive: false,
      cleanHTML: {
        fillEmptyParagraph: false,
      },
      // events: {
      //   keydown: (event) => {
      //     if (event.key === "Tab") {
      //       event.preventDefault(); // Prevent the default tab behavior
      //       event.editor.s.insertHTML("&nbsp;&nbsp;&nbsp;&nbsp;");
      //     }
      //   },
      // },
    }),
    [options],
  );

  const onDrop = useCallback(
    (files) => {
      formik.setFieldValue("cover", files[0]);
      setCoverUrl(URL.createObjectURL(files[0]));
    },
    [formik],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  const handleWriteWithAI = async () => {
    try {
      const response = await generateCourseDescription({
        course_title: formik.values.title,
        course_overview: generateCourseDescriptionFormik.values.course_overview,
        target_customer_and_pain_points:
          generateCourseDescriptionFormik.values
            .target_customer_and_pain_points,
        expected_outcome:
          generateCourseDescriptionFormik.values.expected_outcome,
      });

      setEdittingDescription(true);
      formik.setFieldValue("description", response.data.description);
      setShowGenerateCourseDescriptionDialog(false);
      toast.success(
        "Your AI-powered description is ready! Click save to use it.",
      );
    } catch (err) {
      console.error("Failed to write with AI", err);
      toast.error("Something went wrong!");
    }
  };

  const generateCourseDescriptionFormik = useFormik({
    initialValues: {
      course_overview: "",
      target_customer_and_pain_points: "",
      expected_outcome: "",
    },
    validationSchema: generateCourseDescriptionFormValidationSchema,
    onSubmit: handleWriteWithAI,
  });

  return (
    <>
      <Form className="form-container">
        {!hideHeading && <h4>Overview</h4>}
        <Form.Group>
          <Form.Label>Course title</Form.Label>
          <Form.Control
            type="text"
            name="title"
            placeholder="Intro to yoga from home"
            value={formik.values.title}
            onChange={formik.handleChange}
            isInvalid={!!formik.errors.title && formik.touched.title}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.title}
          </Form.Control.Feedback>
        </Form.Group>

        <Image src={coverUrl ?? formik.values.cover} className="w-100 mt-3" />

        <Form.Group controlId="formFile" {...getRootProps()}>
          <Form.Label>{isEdit ? "Update" : "Upload"} Cover Image</Form.Label>
          <Form.Control
            {...getInputProps()}
            type="file"
            name="cover"
            isInvalid={!!formik.errors.cover && formik.touched.cover}
          />
          <div
            className={`d-flex w-100 justify-content-center pt-3 border ${isDragActive ? "border-success" : "border-success-subtle"} dropzone-container`}
          >
            <p>Drag and drop file here, or click to select files</p>
          </div>
          <Form.Control.Feedback type="invalid">
            {formik.errors.cover}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label className="d-flex justify-content-between align-items-center">
            <span>Description</span>
            <div>
              {!edittingDescription && (
                <Button
                  variant="link"
                  onClick={(e) => {
                    e.preventDefault();
                    setEdittingDescription((prev) => !prev);
                  }}
                  className="p-0 text-end"
                >
                  Edit
                </Button>
              )}
              {edittingDescription && (
                <Button
                  onClick={() => setShowGenerateCourseDescriptionDialog(true)}
                  disabled={formik.values.title?.length === 0}
                >
                  Write with AI
                </Button>
              )}
            </div>
          </Form.Label>
          {!edittingDescription && parse(formik.values.description)}
          {(edittingDescription || !isEdit) && (
            <Form.Control
              as={() => (
                <JoditEditor
                  ref={editor}
                  value={formik.values.description}
                  config={config}
                  onBlur={(description) => {
                    formik.setFieldValue("description", description);
                    setEdittingDescription((prev) => !prev);
                  }} // preferred to use only this option to update the content for performance reasons
                />
              )}
              rows={5}
              name="description"
              placeholder="Describe what your course is about"
              isInvalid={
                !!formik.errors.description && formik.touched.description
              }
            />
          )}

          <Form.Control.Feedback type="invalid">
            {formik.errors.description}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
      <Dialog
        open={showGenerateCourseDescriptionDialog}
        onConfirm={() => {
          generateCourseDescriptionFormik.submitForm();
        }}
        onClose={() => setShowGenerateCourseDescriptionDialog(false)}
        loading={isGenerateCourseDescriptionLoading}
        title="Write with AI"
        body={
          <GenerateCourseDescriptionForm
            formik={generateCourseDescriptionFormik}
          />
        }
        confirmText="Generate"
        confirmVariant="primary"
      />
    </>
  );
};

export default CourseForm;
