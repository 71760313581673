import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, Formik } from "formik";
import { useRef, useState } from "react";
import {
  Button,
  Card,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import * as Yup from "yup";
import Questions from "./Questions";

const DEFAULT_VALUES = {
  question_text: "",
  answers: [
    // { answer_text: "Dummy answer", is_correct: false, isEditting: false },
  ],
};

const QuestionForm = ({ disabled, topicFormik, isLoading }) => {
  const [showQuestionForm, setShowQuestionForm] = useState(false);
  const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false);

  const [initialValues, setInitialValues] = useState(DEFAULT_VALUES);
  const questionBeingDeleted = useRef(null);
  const questionBeingEditted = useRef(null);

  const validationSchema = Yup.object({
    question_text: Yup.string().required("Please type your question"),
    answers: Yup.array().of(
      Yup.object({
        answer_text: Yup.string().required("Answer is required"),
        is_correct: Yup.boolean(),
        isEditting: Yup.boolean(),
      }),
    ),
  });
  const onSubmit = (values, { resetForm }) => {
    let newQuestions = [...topicFormik.values.questions];
    if (newQuestions.length > 0) {
      if (questionBeingEditted.current != null) {
        newQuestions[questionBeingEditted.current] = values;
      } else {
        newQuestions = [...newQuestions, values];
      }
    } else {
      newQuestions.push(values);
    }
    topicFormik.setFieldValue("questions", newQuestions);

    resetForm();
    setShowQuestionForm(false);
    questionBeingEditted.current = null;
    setInitialValues(DEFAULT_VALUES);
    topicFormik?.submitForm();
  };

  const handleCancelQuestion = (formik) => {
    formik.resetForm();
    setShowQuestionForm(false);
  };

  const handleAddNewQuestionClick = () => {
    topicFormik.submitForm().then((createdTopic) => {
      if (createdTopic) {
        setShowQuestionForm(true && !disabled);
      }
    });
  };

  const renderAddNewQuestionButton = () => {
    return (
      !showQuestionForm && (
        <div
          className={`add-new-question ${disabled ? "disabled" : ""}`}
          onClick={handleAddNewQuestionClick}
        >
          <FontAwesomeIcon className="m-2" icon={faPlus} />
          Add a new question
        </div>
      )
    );
  };

  const handleDeleteQuestion = () => {
    if (questionBeingDeleted.current != null) {
      const currentQuestions = [...topicFormik.values.questions];
      currentQuestions.splice(questionBeingDeleted.current, 1);
      topicFormik.setFieldValue("questions", currentQuestions);
      topicFormik.submitForm().then(() => handleCloseDeleteQuestionModal());
    }
  };

  const handleEditQuestion = (questionIndex) => {
    setShowQuestionForm(true);
    setInitialValues(topicFormik?.values.questions[questionIndex]);
    questionBeingEditted.current = questionIndex;
  };

  const handleCloseDeleteQuestionModal = () => {
    setShowDeleteQuestionModal(false);
    questionBeingDeleted.current = null;
  };

  const handleShowDeleteQuestionModal = (questionIndex) => {
    setShowDeleteQuestionModal(true);
    questionBeingDeleted.current = questionIndex;
  };

  return (
    <>
      <Questions
        questions={topicFormik?.values.questions}
        onDelete={handleShowDeleteQuestionModal}
        onEdit={handleEditQuestion}
      />
      {disabled ? (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="tooltip">
              <strong>Complete the topic info to add your questions!</strong>
            </Tooltip>
          }
        >
          {renderAddNewQuestionButton()}
        </OverlayTrigger>
      ) : (
        renderAddNewQuestionButton()
      )}
      {showQuestionForm && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => {
            return (
              <Form className="section form-container" as={Card}>
                <Card.Body>
                  <Form.Group>
                    <Form.Label>Question</Form.Label>
                    <Form.Control
                      aria-label="Question"
                      as="textarea"
                      rows={2}
                      placeholder="Type your question..."
                      name="question_text"
                      value={formik.values.question_text}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        !!formik.errors.question_text &&
                        formik.touched.question_text
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.question_text}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <FieldArray
                    name="answers"
                    render={(arrayHelpers) => (
                      <div className="answers-list mt-2">
                        {formik.values.answers.length > 0
                          ? formik.values.answers.map((answer, answerIndex) => (
                              <div className="answers-list" key={answerIndex}>
                                {!answer.isEditting && (
                                  <div className="answer-option">
                                    <div className="mt-2 w-100">
                                      <small className="text-muted">
                                        <strong>
                                          {" "}
                                          Answer {answerIndex + 1}
                                        </strong>
                                      </small>
                                      <p className="answer-text">
                                        {answer.answer_text}
                                      </p>
                                      <div
                                        className={`d-flex align-items-center justify-content-${answer.is_correct ? "between" : "end"}`}
                                      >
                                        {answer.is_correct && (
                                          <small className="text-muted">
                                            Marked as correct
                                          </small>
                                        )}
                                        <div className="d-flex justify-content-end">
                                          <Button
                                            variant="link"
                                            size="sm"
                                            onClick={() => {
                                              formik.setFieldValue(
                                                `answers.${answerIndex}.isEditting`,
                                                true,
                                              );
                                            }}
                                          >
                                            Edit
                                          </Button>
                                          <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => {
                                              arrayHelpers.remove(answerIndex);
                                            }}
                                          >
                                            Delete
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {answer.isEditting && (
                                  <div className="answer-option">
                                    <div className="w-100">
                                      <small className="text-muted d-inline-block mt-2">
                                        <strong>
                                          {" "}
                                          Answer {answerIndex + 1}
                                        </strong>
                                      </small>
                                      <Form.Group>
                                        <Form.Control
                                          className="mt-2"
                                          type="text"
                                          as="textarea"
                                          rows={2}
                                          value={answer.answer_text}
                                          name={`answers.${answerIndex}.answer_text`}
                                          onChange={formik.handleChange}
                                          isInvalid={
                                            !!formik.errors.answers?.[
                                              answerIndex
                                            ]?.answer_text &&
                                            formik.touched.answers?.[
                                              answerIndex
                                            ]?.answer_text
                                          }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {
                                            formik.errors.answers?.[answerIndex]
                                              ?.answer_text
                                          }
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                      <div className="d-flex justify-content-between align-items-center mt-2">
                                        <Form.Check
                                          className="mt-1"
                                          type="checkbox"
                                          id={`answers-${answerIndex}-checkbox`}
                                          label="Mark as correct"
                                          name={`answers.${answerIndex}.is_correct`}
                                          onChange={formik.handleChange}
                                          checked={answer.is_correct}
                                        />
                                        <div>
                                          <Button
                                            size="sm"
                                            className="me-2"
                                            variant="light"
                                            onClick={() => {
                                              arrayHelpers.remove(answerIndex);
                                            }}
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            size="sm"
                                            variant="primary"
                                            onClick={() => {
                                              formik.setFieldTouched(
                                                `answers.${answerIndex}.answer_text`,
                                                true,
                                                true,
                                              );
                                              formik
                                                .validateForm()
                                                .then((errors) => {
                                                  if (
                                                    !errors.answers?.[
                                                      answerIndex
                                                    ]
                                                  ) {
                                                    formik.setFieldValue(
                                                      `answers.${answerIndex}.isEditting`,
                                                      false,
                                                    );
                                                  }
                                                });
                                            }}
                                          >
                                            Save
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))
                          : null}
                        <div
                          className="add-answer"
                          onClick={() => {
                            formik.setFieldTouched(
                              `answers.${formik.values.answers.length - 1}.answer_text`,
                              true,
                              true,
                            );
                            formik.validateForm().then((errors) => {
                              let allowAdd = true;
                              for (
                                let i = 0;
                                i < formik.values.answers.length;
                                i++
                              ) {
                                if (!errors.answers?.[i]) {
                                  formik.setFieldValue(
                                    `answers.${i}.isEditting`,
                                    false,
                                  );
                                } else {
                                  allowAdd = false;
                                  break;
                                }
                              }
                              if (allowAdd) {
                                arrayHelpers.push({
                                  answer_text: "",
                                  is_correct: false,
                                  isEditting: true,
                                });
                              }
                            });
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="add-answer-icon"
                            size="sm"
                          />
                          <span>Add answer</span>
                        </div>
                      </div>
                    )}
                  />

                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      variant="light"
                      className="me-2"
                      size="sm"
                      onClick={() => handleCancelQuestion(formik)}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="sm"
                      variant="primary"
                      type="submit"
                      disabled={
                        !formik.values.question_text ||
                        formik.errors.question_text ||
                        formik.values.answers.some(
                          (answer) => !answer.answer_text,
                        ) ||
                        !formik.values.answers.some(
                          (answer) => answer.is_correct,
                        )
                      }
                      onClick={formik.handleSubmit}
                    >
                      Save question
                    </Button>
                  </div>
                </Card.Body>
              </Form>
            );
          }}
        </Formik>
      )}
      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteQuestionModal}
        onHide={handleCloseDeleteQuestionModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this question? This action cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteQuestionModal}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteQuestion}
            disabled={isLoading}
          >
            {isLoading ? <Spinner size="sm" animation="border" /> : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QuestionForm;
