import { useDispatch } from "react-redux";
import { persistor } from "../../store";
import { logout } from "../../redux/auth";
import { apiSlice } from "../../redux/api";

const useLogout = () => {
  const dispatch = useDispatch();
  const handleLogout = async () => {
    try {
      dispatch(logout());
      await persistor.flush();
      persistor.purge();
      dispatch(apiSlice.util.resetApiState());
    } catch (err) {
      console.error("Error during sign out:", err);
    }
  };
  return handleLogout;
};

export default useLogout;
