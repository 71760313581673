import Form from "react-bootstrap/Form";

const ChapterInfoForm = ({ formik }) => {
  return (
    <Form className="form-container">
      <div className="d-flex justify-content-between align-items-center">
        <h4>Chapter Overview</h4>
        <Form.Check
          type="switch"
          id="hidden-checkbox"
          label={!formik.values.hidden ? "Published" : "Unpublished"}
          name="hidden"
          placeholder="In this chapter we will learn..."
          checked={!formik.values.hidden}
          onChange={formik.handleChange}
          isInvalid={!!formik.errors.hidden && formik.touched.hidden}
        />
      </div>
      <Form.Group>
        <Form.Label>Chapter title</Form.Label>
        <Form.Control
          type="text"
          name="title"
          placeholder="Introduction to yoga from home"
          value={formik.values.title}
          onChange={formik.handleChange}
          isInvalid={!!formik.errors.title && formik.touched.title}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.title}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          name="description"
          placeholder="In this chapter we will learn..."
          value={formik.values.description}
          onChange={formik.handleChange}
          isInvalid={!!formik.errors.description && formik.touched.description}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.description}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
};

export default ChapterInfoForm;
