import NewTopic from "./new/NewTopic";
import EditTopic from "./edit/EditTopic";

const Topic = ({ mode }) => {
  if (mode === "add") {
    return <NewTopic />;
  }

  if (mode === "edit") {
    return <EditTopic />;
  }

  // if (mode === 'view') {
  //     return <ViewTopic />
  // }
};

export default Topic;
