import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

function Course({ course }) {
  return (
    <Card className="h-100">
      <Card.Img variant="top" src={course.cover} />
      <Card.Body>
        <Card.Title>
          <h6>{course.title}</h6>
        </Card.Title>
        <Card.Text className="text-muted">
          {course.chapters.length} Chapters &bull;{" "}
          {course.students?.length ?? 0} Students
        </Card.Text>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end">
        <Button
          className="unset-button-width me-1"
          variant="light"
          size="sm"
          as={Link}
          target="_blank"
          to={`/course/${course.id}`}
        >
          View
        </Button>
        <Button
          className="unset-button-width"
          variant="light"
          size="sm"
          as={Link}
          to={`/app/course/${course.id}/edit`}
        >
          Edit
        </Button>
      </Card.Footer>
    </Card>
  );
}

export default Course;
