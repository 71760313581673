import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { apiSlice } from "./api";

const initialState = {
  isAuthenticated: false,
  user: null,
  accessToken: Cookies.get("accessToken") || null,
  refreshToken: Cookies.get("refreshToken") || null,
  role: Cookies.get("role") || null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.role = action.payload.role;

      // Store tokens in cookies
      Cookies.set("accessToken", action.payload.accessToken, { secure: true });
      Cookies.set("refreshToken", action.payload.refreshToken, {
        secure: true,
      });
      Cookies.set("role", action.payload.role, { secure: true });
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.role = null;

      // Clear tokens from cookies
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      Cookies.remove("role");
    },
    rehydrateAuth: (state) => {
      // Set state from cookies if they exist
      state.isAuthenticated = !!Cookies.get("accessToken");
      state.accessToken = Cookies.get("accessToken");
      state.refreshToken = Cookies.get("refreshToken");
      state.role = Cookies.get("role");
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.fetchUser.matchFulfilled,
      (state, action) => {
        state.user = action.payload;
        window.pendo.initialize({
          visitor: {
            id: state.user.id,
            email: state.user.email,
            firstName: state.user.first_name,
            lastName: state.user.last_name,
          },
          account: {
            id: state.user.id,
            accountName: state.role,
            payingStatus: "free",
          },
        });
      },
    );
  },
  selectors: {
    selectCurrentUser: (state) => state.user,
    selectCurrentUserRole: (state) => state.role,
  },
});

export const { loginSuccess, logout, rehydrateAuth } = authSlice.actions;
export const { selectCurrentUser, selectCurrentUserRole } = authSlice.selectors;
export default authSlice.reducer;
