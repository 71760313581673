import { useCallback, useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { useDropzone } from "react-dropzone";

const TopicForm = ({ formik }) => {
  const [videoUrl, setVideoUrl] = useState();
  const videoRef = useRef(null);
  const [filename, setFilename] = useState(null);

  const onDrop = useCallback(
    (files) => {
      setFilename(files[0].name);
      formik.setFieldValue("videoFile", files[0]);
      setVideoUrl(URL.createObjectURL(files[0]));
    },
    [formik],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "video/mp4": [".mp4"],
      "video/webm": [".webm"],
      "video/ogg": [".ogv", ".ogg"],
      "video/x-msvideo": [".avi"],
      "video/quicktime": [".mov"],
      "video/x-matroska": [".mkv"],
    },
  });

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [videoUrl]);

  return (
    <Form className="form-container">
      <div className="d-flex justify-content-between align-items-center">
        <h4>Topic Overview</h4>
        <Form.Check
          type="switch"
          id="hidden-checkbox"
          label={!formik.values.hidden ? "Published" : "Unpublished"}
          name="hidden"
          placeholder="In this chapter we will learn..."
          checked={!formik.values.hidden}
          onChange={formik.handleChange}
          isInvalid={!!formik.errors.hidden && formik.touched.hidden}
        />
      </div>
      <Form.Group>
        <Form.Label>Topic Title</Form.Label>
        <Form.Control
          type="text"
          name="title"
          placeholder="Topic title"
          value={formik.values.title}
          onChange={formik.handleChange}
          isInvalid={!!formik.errors.title && formik.touched.title}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.title}
        </Form.Control.Feedback>
      </Form.Group>

      {(videoUrl || formik.values.videoFile) && (
        <video ref={videoRef} controls className="video mt-3">
          <source src={videoUrl ?? formik.values.videoFile} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      <Form.Group controlId="formFile" {...getRootProps()}>
        <Form.Label>
          {videoUrl || formik.values.videoFile ? "Change Video" : "Video"}
        </Form.Label>
        <Form.Control
          {...getInputProps()}
          type="file"
          name="videoFile"
          isInvalid={!!formik.errors.videoFile && formik.touched.videoFile}
        />
        <div
          className={`d-flex w-100 justify-content-center pt-3 border ${isDragActive ? "border-success" : "border-success-subtle"} dropzone-container`}
        >
          <p>
            {filename ?? "Drag and drop file here, or click to select files"}
          </p>
        </div>
        <Form.Control.Feedback type="invalid">
          {formik.errors.videoFile}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>Topic Description</Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          name="description"
          placeholder="Topic Description"
          value={formik.values.description}
          onChange={formik.handleChange}
          isInvalid={!!formik.errors.description && formik.touched.description}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.description}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
};

export default TopicForm;
