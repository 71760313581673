import { Col, Container, Navbar, Row } from "react-bootstrap";
import Logo from "../shared/components/Logo";
import { Link } from "react-router-dom";
function NotFoundPage() {
  return (
    <>
      <Navbar expand="lg" className="bg-primary app-navbar" sticky="top">
        <Container>
          <Navbar.Brand as={Link} to="https://coursable.co">
            <Logo otherClasses="mt-2" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Container>
      </Navbar>
      <Container className="mt-5">
        <Row>
          <Col className="d-flex justify-content-center">
            <h1>Oops.. not sure what you meant!</h1>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default NotFoundPage;
