import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { Breadcrumb, Button, Col, Row, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useDeleteTopicMutation,
  useGetTopicQuery,
  useUpdateTopicMutation,
} from "../../../redux/api";
import useStorageUpload from "../../../shared/hooks/useStorageUpload.hook";
import QuestionForm from "../QuestionForm";
import "../Topic.css";
import TopicForm from "../TopicForm"; // Renamed from VideoForm
import validationSchema from "../validationSchema";
import { v4 as uuid } from "uuid";
import Dialog from "../../../shared/components/Dialog";
import { useState } from "react";

const EditTopic = () => {
  const navigate = useNavigate();
  let { courseId, chapterId, topicId } = useParams();

  const [updateTopic, { isLoading: isUpdateTopicLoading }] =
    useUpdateTopicMutation();

  const { uploadFileToStorage, isLoading: isUploadingFile } =
    useStorageUpload();

  const [deleteTopic, { isLoading: isDeleteTopicLoading }] =
    useDeleteTopicMutation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data: topic, isLoading: isGetTopicLoading } = useGetTopicQuery(
    topicId,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const handleSubmitTopic = async () => {
    try {
      const formData = new FormData();
      formData.append("chapter", chapterId);
      formData.append("title", formik.values?.title);
      formData.append("hidden", formik.values?.hidden);
      formData.append("description", formik.values?.description);
      if (formik.values.videoFile instanceof File) {
        const videoUuid = uuid();
        await uploadFileToStorage(
          new File([formik.values.videoFile], `videos/${videoUuid}`, {
            type: formik.values.videoFile.type,
          }),
        );
        formData.append("video_file", `videos/${videoUuid}`);
      }

      formik.values.questions?.forEach((question, questionIndex) => {
        formData.append(`questions[${questionIndex}].topic`, topicId);
        formData.append(
          `questions[${questionIndex}].question_text`,
          question.question_text,
        );
        question.answers.forEach((answer, answerIndex) => {
          formData.append(
            `questions[${questionIndex}].answers[${answerIndex}].answer_text`,
            answer.answer_text,
          );
          formData.append(
            `questions[${questionIndex}].answers[${answerIndex}].is_correct`,
            answer.is_correct,
          );
        });
      });
      const createdTopic = await updateTopic({
        topicId,
        topicData: formData,
      }).unwrap();
      return createdTopic;
    } catch (err) {
      console.error("Failed to update topic:", err);
      toast.error("Something went wrong.");
    }
  };

  const handleDelete = async () => {
    try {
      await deleteTopic(topicId).unwrap();
      toast.success("Topic deleted successfully!");
      navigate(`/app/course/${courseId}/chapter/${chapterId}/edit`);
    } catch (err) {
      toast.error("Failed to delete topic");
    }
  };

  const formik = useFormik({
    initialValues: {
      title: topic?.title ?? "",
      description: topic?.description ?? "",
      videoFile: topic?.video_file_url ?? "",
      questions: topic?.questions ?? [],
      hidden: topic?.hidden ?? false,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmitTopic,
  });

  const getSaveButtonText = () => {
    if (isUploadingFile) {
      return "Uploading video...";
    }

    return "Save";
  };

  if (isGetTopicLoading) return <Spinner />;

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => navigate(`/app/course/${courseId}/edit`)}
        >
          Course
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() =>
            navigate(`/app/course/${courseId}/chapter/${chapterId}/edit`)
          }
        >
          Chapter
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Topic</Breadcrumb.Item>
      </Breadcrumb>
      <Row className="w-100 mb-5">
        <Col sm={12}>
          <TopicForm formik={formik} />
          <div sm={12} className="form-container section">
            <h4>Questions</h4>
            <QuestionForm
              topicFormik={formik}
              isLoading={isUpdateTopicLoading}
            />
          </div>
          <div className="form-container d-flex justify-content-between align-items-center mt-3">
            <Button
              variant="light"
              as={Link}
              to={`/app/course/${courseId}/chapter/${chapterId}/edit`}
            >
              <FontAwesomeIcon icon={faChevronLeft} /> Back
            </Button>
            <div className="mt-0">
              <Button
                variant="link"
                className="text-danger"
                onClick={() => {
                  setShowDeleteModal(true);
                }}
                disabled={isDeleteTopicLoading}
              >
                Delete
              </Button>
              <Button
                type="button"
                onClick={() => {
                  formik.submitForm().then((createdTopic) => {
                    if (createdTopic) {
                      toast.success("Topic updated successfully");
                    }
                  });
                }}
                disabled={isUpdateTopicLoading || isUploadingFile}
              >
                {getSaveButtonText()}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Dialog
        open={showDeleteModal}
        onConfirm={handleDelete}
        onClose={() => setShowDeleteModal(false)}
        loading={isDeleteTopicLoading}
        title="Confirm Delete"
        body="Are you sure you want to delete this topic? This action cannot be undone."
        confirmText="Delete"
      />
    </>
  );
};

export default EditTopic;
