import NewChapter from "./new/NewChapter";
import EditChapter from "./edit/EditChapter";

const Chapter = ({ mode }) => {
  if (mode === "add") {
    return <NewChapter />;
  }

  if (mode === "edit") {
    return <EditChapter />;
  }

  // if (mode === 'view') {
  //     return <ViewChapter />
  // }
};

export default Chapter;
