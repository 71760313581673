import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import "./AuthenticatedLayout.css";
import Logo from "../shared/components/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import useLogout from "../shared/hooks/useLogout.hook";
import { selectCurrentUserRole } from "../redux/auth";
import { useFetchUserQuery } from "../redux/api";

function AuthenticatedLayout() {
  const navigate = useNavigate();
  const handleLogout = useLogout();

  useFetchUserQuery();

  // Select authentication state from Redux store
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const currentUserRole = useSelector(selectCurrentUserRole);

  // Check authentication on component mount
  useEffect(() => {
    if (!isAuthenticated || currentUserRole === "student") {
      // Redirect to login if not authenticated
      navigate("/signin");
    }
  }, [isAuthenticated, navigate, currentUserRole]);

  return (
    <>
      <Navbar expand="lg" className="app-navbar" sticky="top">
        <Container>
          <Navbar.Brand href="/app/dashboard">
            <Logo otherClasses="mt-2" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="d-flex align-items-center">
              <Nav.Link as={Link} to="/app/dashboard" className="text-white">
                Dashboard
              </Nav.Link>
              <NavDropdown
                placement="left"
                align="end"
                id="basic-nav-dropdown"
                title={
                  <FontAwesomeIcon
                    icon={faCircleUser}
                    size="lg"
                    className="text-white"
                  />
                }
              >
                <NavDropdown.Item as={Link} to="/app/settings">
                  Account
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleLogout}>
                  Sign out
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="outlet-container">
        <Outlet />
      </Container>
      <footer className="footer">
        <Container className="d-flex justify-content-between w-100 ">
          <div>
            Copyright &copy; AppHive Technologies {new Date().getFullYear()}
          </div>
          <div>
            <Link className="me-4">Privacy Policy</Link>
            <Link>Terms and Conditions</Link>
          </div>
        </Container>
      </footer>
    </>
  );
}

export default AuthenticatedLayout;
