import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import React from "react";
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";

import toast, { ToastBar, Toaster } from "react-hot-toast";
import { Provider } from "react-redux";

import { persistor, store } from "./store";

import { PersistGate } from "redux-persist/integration/react";
import Activation from "./activation/Activation";
import AuthenticatedLayout from "./authenticated/AuthenticatedLayout";
import Chapter from "./authenticated/chapter/Chapter";
import CourseWizardLayout from "./authenticated/course/CourseWizardLayout";
import CourseSettings from "./authenticated/course/edit/course-settings/CourseSettings";
import EditCourse from "./authenticated/course/edit/EditCourse";
import Enrollments from "./authenticated/course/enrollments/Enrollments";
import NewCourse from "./authenticated/course/new/NewCourse";
import ViewCourse from "./authenticated/course/view/ViewCourse";
import Dashboard from "./authenticated/dashboard/Dashboard";
import Settings from "./authenticated/settings/Settings";
import Topic from "./authenticated/topic/Topic";
import NotFoundPage from "./not-found-page/NotFoundPage";
import SignIn from "./signin/SignIn";
import SignUp from "./signup/SignUp";

const router = createBrowserRouter([
  {
    path: "/",
    loader: () => {
      const state = store.getState();
      if (state.auth.isAuthenticated) {
        return redirect("/app/dashboard");
      } else {
        return redirect("/signin");
      }
    },
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/activate",
    element: <Activation />,
  },
  {
    path: "/app",
    element: <AuthenticatedLayout />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "course/add",
        element: <NewCourse />,
      },
      {
        path: "course/:courseId",
        element: <CourseWizardLayout />,
        children: [
          {
            path: "edit",
            element: <EditCourse mode="edit" />,
          },
          {
            path: "chapter/add",
            element: <Chapter mode="add" />,
          },
          {
            path: "chapter/:chapterId/edit",
            element: <Chapter mode="edit" />,
          },
          {
            path: "chapter/:chapterId/topic/add",
            element: <Topic mode="add" />,
          },
          {
            path: "chapter/:chapterId/topic/:topicId/edit",
            element: <Topic mode="edit" />,
          },
          {
            path: "settings",
            element: <CourseSettings />,
          },
          {
            path: "enrollments",
            element: <Enrollments />,
          },
        ],
      },
    ],
  },
  {
    path: "view-course/:courseId",
    element: <ViewCourse />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/*",
    element: <NotFoundPage />,
  },
]);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router}></RouterProvider>
        <Toaster
          position="top-right"
          toastOptions={{
            success: {
              duration: Infinity,
            },
            error: {
              duration: Infinity,
            },
            loading: {
              duration: Infinity,
            },
            blank: {
              duration: Infinity,
            },
          }}
        >
          {(t) => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <div className="d-flex align-items-center">
                  <div className="d-flex">
                    {icon}
                    {message}
                  </div>
                  {t.type !== "loading" && (
                    <button
                      type="button"
                      class="btn close"
                      aria-label="Close"
                      onClick={() => toast.dismiss(t.id)}
                      style={{ minWidth: "0" }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  )}
                </div>
              )}
            </ToastBar>
          )}
        </Toaster>
      </PersistGate>
    </Provider>
  );
}

export default App;
