import {
  faChevronRight,
  faPlus,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, ListGroup, Row } from "react-bootstrap";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useGetCourseQuery } from "../../redux/api";
import "./CourseWizardLayout.css";

const CourseWizardLayout = () => {
  const params = useParams();
  const location = useLocation();
  const key = location.pathname.split("/").at(-1);
  const { data: course } = useGetCourseQuery(params.courseId);

  const navigate = useNavigate();

  const renderTimeline = () => {
    if (course?.chapters?.length === 0) {
      return (
        <div className="text-muted">
          Your course outline will appear here once you start making progress.
          Try saving a chapter and a topic.
        </div>
      );
    }

    return course?.chapters?.map((c) => {
      return (
        <div key={c.id}>
          <Link to={`/app/course/${course.id}/chapter/${c.id}/edit`}>
            <p>
              {parseInt(params.chapterId, 10) === c.id ? (
                <strong>{c.title}</strong>
              ) : (
                c.title
              )}
            </p>
          </Link>
          <ul>
            {c.topics.map((t) => (
              <Link
                to={`/app/course/${course.id}/chapter/${c.id}/topic/${t.id}/edit`}
                key={t.id}
              >
                <li className="text-muted">
                  {parseInt(params.topicId, 10) === t.id ? (
                    <strong>{t.title}</strong>
                  ) : (
                    t.title
                  )}
                </li>
              </Link>
            ))}
          </ul>
        </div>
      );
    });
  };

  return (
    <>
      <Row className="w-100 mb-5">
        <Col lg={3}>
          <ListGroup className="edit-menu">
            <ListGroup.Item
              as={Link}
              to={`/app/course/${params.courseId}/edit`}
              active={key === "edit"}
            >
              Course Content
              <FontAwesomeIcon
                className={`text-muted ${key === "edit" ? "active" : ""}`}
                icon={faChevronRight}
              />
            </ListGroup.Item>
            <ListGroup.Item
              as={Link}
              to={`/app/course/${params.courseId}/enrollments`}
              active={key === "enrollments"}
            >
              Enrollments
              <FontAwesomeIcon
                className={`text-muted ${key === "enrollments" ? "active" : ""}`}
                icon={faChevronRight}
              />
            </ListGroup.Item>
            <ListGroup.Item
              as={Link}
              to={`/app/course/${params.courseId}/settings`}
              active={key === "settings"}
            >
              Publish & Settings
              <FontAwesomeIcon
                className={`text-muted ${key === "settings" ? "active" : ""}`}
                icon={faChevronRight}
              />
            </ListGroup.Item>
            <ListGroup.Item
              as={Link}
              to={`/course/${params.courseId}/`}
              target="_blank"
            >
              Preview
              <FontAwesomeIcon
                icon={faUpRightFromSquare}
                className="text-muted"
              />
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col sm={6} className="d-flex flex-column">
          <Outlet />
        </Col>
        {/* {key === "edit" && ( */}
        <Col sm={3} className="d-flex flex-column course-outline">
          <h5 className="mb-3">Quick navigation</h5>
          {renderTimeline()}
          <div
            className="d-flex add-new-chapter quick-nav-action"
            onClick={() => navigate(`/app/course/${course?.id}/chapter/add`)}
          >
            <FontAwesomeIcon className="m-2" icon={faPlus} />
            Add a new chapter
          </div>
        </Col>
        {/* )} */}
      </Row>
    </>
  );
};

export default CourseWizardLayout;
