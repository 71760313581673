import { Form } from "react-bootstrap";

const GenerateCourseDescriptionForm = ({ formik }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <p>
        <strong>
          Answer a few questions to generate the most personalized course
          description:
        </strong>
      </p>
      <Form className="form-container">
        <Form.Group>
          <Form.Label>What is this course about</Form.Label>
          <Form.Control
            type="text"
            as="textarea"
            rows={2}
            name="course_overview"
            placeholder="For programmers to learn design fundamentals"
            value={formik.values.course_overview}
            onChange={formik.handleChange}
            isInvalid={
              !!formik.errors.course_overview && formik.touched.course_overview
            }
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.course_overview}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Who is your target customer and their pain points
          </Form.Label>
          <Form.Control
            type="text"
            as="textarea"
            rows={2}
            name="target_customer_and_pain_points"
            placeholder="Programmers with good engineering but average design skills"
            value={formik.values.target_customer_and_pain_points}
            onChange={formik.handleChange}
            isInvalid={
              !!formik.errors.target_customer_and_pain_points &&
              formik.touched.target_customer_and_pain_points
            }
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.target_customer_and_pain_points}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            What outcome will they get after completing this course
          </Form.Label>
          <Form.Control
            type="text"
            as="textarea"
            rows={2}
            name="expected_outcome"
            placeholder="They will be much better at providing a good UX"
            value={formik.values.expected_outcome}
            onChange={formik.handleChange}
            isInvalid={
              !!formik.errors.expected_outcome &&
              formik.touched.expected_outcome
            }
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.expected_outcome}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
    </div>
  );
};

export default GenerateCourseDescriptionForm;
