import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useCreateCourseMutation } from "../../../redux/api";
import "../Course.css";
import CourseForm from "../CourseForm";
import validationSchema from "../validationSchema";

const NewCourse = () => {
  const navigate = useNavigate();
  const [createCourse, { isLoading }] = useCreateCourseMutation();

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("title", formik.values.title);
      formData.append("description", formik.values.description);
      formData.append("cover", formik.values.cover);
      const createdCourse = await createCourse(formData).unwrap();

      return createdCourse;
    } catch (err) {
      console.error("Failed to create course:", err);
      toast.error("Something went wrong.");
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      cover: null,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleAddNewChapter = () => {
    formik.submitForm().then((createdCourse) => {
      if (createdCourse) {
        navigate(`/app/course/${createdCourse.id}/chapter/add`);
      }
    });
  };

  const handleSave = () => {
    formik.submitForm().then((createdCourse) => {
      if (createdCourse) {
        navigate(`/app/course/${createdCourse.id}/edit`);
        toast.success("Course saved successfully!");
      }
    });
  };

  return (
    <>
      <Row>
        <Col className="d-flex flex-column justify-content-center align-items-center header">
          <h1 className="form-heading">New Course</h1>
          <p className="text-muted">
            We are creating a new course. You can save your progress to continue
            later.
          </p>
        </Col>
      </Row>

      <Row className="w-100 mb-5">
        <Col
          sm={12}
          className="d-flex flex-column justify-content-center align-items-center section"
        >
          <CourseForm formik={formik} />

          <div
            className="d-flex form-container add-new-chapter mt-3"
            onClick={handleAddNewChapter}
          >
            <FontAwesomeIcon className="m-2" icon={faPlus} />
            Add a new chapter
          </div>

          <div className="d-flex form-container justify-content-between section">
            <Button variant="link" as={Link} to="/app/dashboard">
              Back
            </Button>
            <Button variant="primary" onClick={handleSave} disabled={isLoading}>
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NewCourse;
