import {
  faMessage as faMessageOff,
  faPaperPlane,
  faRectangleList as faRectangleListOff,
} from "@fortawesome/free-regular-svg-icons";
import {
  faLightbulb,
  faMessage,
  faRectangleList,
} from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faBookmark } from "@fortawesome/free-solid-svg-icons/faBookmark";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isMobile from "is-mobile";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Accordion,
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Navbar,
  Offcanvas,
  Row,
} from "react-bootstrap";
import toast from "react-hot-toast";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  useAskLLMDuringVideoMutation,
  useFetchUserQuery,
  useGetChatMessagesQuery,
  useGetPublicCourseQuery,
  useResendActivationMutation,
  useUpdateCourseProgressMutation,
} from "../../../redux/api";
import { selectCurrentUser } from "../../../redux/auth";
import Logo from "../../../shared/components/Logo";
import HyperlinkParsedText from "../../../shared/components/HyperlinkParsedText";
import "./ViewCourse.css";

function ViewCourse() {
  useFetchUserQuery();
  const [showContents, setShowContents] = useState(isMobile() ? false : true);
  const [showNerdi, setShowNerdi] = useState(true);
  const [question, setQuestion] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [updateCourseProgress] = useUpdateCourseProgressMutation();
  const currentUser = useSelector(selectCurrentUser);

  const videoRef = useRef(null);
  const { courseId } = useParams();

  const {
    data: course,
    isLoading,
    isError,
  } = useGetPublicCourseQuery(courseId);

  const [selectedTopic, setSelectedTopic] = useState(null);

  const getLastWatchedChapterIndex = useCallback(() => {
    if (course?.last_watched_topic) {
      const chapterIndex = course?.chapters.findIndex((chapter) =>
        chapter.topics.some((topic) => topic.id === course?.last_watched_topic),
      );
      return chapterIndex;
    }

    return 0;
  }, [course?.chapters, course?.last_watched_topic]);

  useEffect(() => {
    if (course && course.chapters && course.chapters.length > 0) {
      const firstChapter = course.chapters[getLastWatchedChapterIndex()];
      if (firstChapter?.topics && firstChapter?.topics?.length > 0) {
        setSelectedTopic(
          firstChapter.topics.find(
            (topic) => topic.id === course?.last_watched_topic,
          ) ?? firstChapter.topics[0],
        );
        if (videoRef.current) {
          videoRef.current.load();
        }
      }
    }
  }, [course, getLastWatchedChapterIndex]);

  const [askLLMDuringVideo, { isLoading: isAskLLMLoading }] =
    useAskLLMDuringVideoMutation();
  const [resendActivation] = useResendActivationMutation();

  const { data: chatHistory } = useGetChatMessagesQuery(selectedTopic?.id, {
    skip: !selectedTopic,
  });

  const handleAskQuestion = async () => {
    if (!question || !selectedTopic) return;

    const currentTimestamp = videoRef.current
      ? videoRef.current.currentTime
      : 0;

    const newChatMessages = [
      ...chatMessages,
      { role: "user", message: question },
    ];

    setChatMessages(newChatMessages);

    try {
      const data = await askLLMDuringVideo({
        topic_id: selectedTopic.id,
        question,
        current_timestamp: currentTimestamp,
      }).unwrap();

      setChatMessages((prevMessages) => [
        ...prevMessages,
        { role: "assistant", message: data.response },
      ]);

      setQuestion("");
    } catch (error) {
      console.error("Error asking question:", error);
    }
  };

  const handleTopicSelect = (topic) => {
    setSelectedTopic(topic);
    updateCourseProgress({
      courseId,
      topicId: topic.id,
    });
    if (videoRef.current) {
      videoRef.current.load();
    }
    if (isMobile()) {
      setShowContents(false);
    }
  };

  useEffect(() => {
    if (chatHistory) {
      setChatMessages(chatHistory);
    }
  }, [chatHistory]);

  const renderChapters = () => (
    <>
      <h5 className="ms-1 my-2">Chapters</h5>
      <hr />
      <Accordion
        className="chapters-container"
        defaultActiveKey={getLastWatchedChapterIndex().toString()}
        alwaysOpen
      >
        {course?.chapters?.map((chapter, index) => (
          <Accordion.Item eventKey={index.toString()} key={chapter.id}>
            <Accordion.Header className="chapter-title">
              <strong>{chapter.title}</strong>
            </Accordion.Header>
            <Accordion.Body className="topic-items">
              {chapter.topics?.map((topic, index) => (
                <div
                  onClick={() => handleTopicSelect(topic)}
                  key={index}
                  className="d-flex gap-3 align-items-center justify-content-between"
                >
                  {topic.title}
                  {topic.id === course?.last_watched_topic && (
                    <FontAwesomeIcon icon={faBookmark} />
                  )}
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );

  const handleResendActivation = async () => {
    await resendActivation({
      email: currentUser.email,
    });
    toast.success("Activation email sent successfully!");
  };

  console.log(selectedTopic ? selectedTopic.video_file_url : "");

  if (isLoading) return <p>Loading topics...</p>;
  if (isError) return <p>There was an error loading the topics.</p>;

  return (
    <div className="course-viewer">
      {!currentUser?.is_active && (
        <Alert variant="info" className="mb-0">
          Kindly verify your account to continue enjoying full access to this
          course.{" "}
          <small
            className="btn-link"
            role="button"
            onClick={handleResendActivation}
          >
            Resend activation email
          </small>
        </Alert>
      )}
      <Navbar expand="lg" className="bg-primary" sticky="top">
        <Container
          fluid
          className="d-flex justify-content-start align-items-center"
        >
          {isMobile() && (
            <Button
              variant="light"
              className="unset-button-width"
              onClick={() => setShowContents(true)}
            >
              <FontAwesomeIcon icon={faBars} />
            </Button>
          )}
          <Navbar.Brand as="a" href={`/course/${courseId}`}>
            <small>
              <Logo otherClasses="text-white ms-2 mt-2" />
            </small>
          </Navbar.Brand>
          {!isMobile() && (
            <h1 className="course-title">
              <small>{course.title}</small>
            </h1>
          )}
        </Container>
      </Navbar>
      <Container fluid className="course-container">
        <Row className="h-100">
          {showContents && !isMobile() && (
            <Col xs={12} md={3} lg={2} className="curriculum-column">
              {renderChapters()}
            </Col>
          )}
          {isMobile() && (
            <Offcanvas
              show={showContents}
              onHide={() => setShowContents(false)}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Contents</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>{renderChapters()}</Offcanvas.Body>
            </Offcanvas>
          )}
          <Col
            xs={12}
            md={
              showContents && showNerdi ? 6 : showContents || showNerdi ? 9 : 12
            }
            lg={6}
            className="video-column"
          >
            {selectedTopic && (
              <video ref={videoRef} controls className="video">
                <source
                  src={selectedTopic ? selectedTopic.video_file_url : ""}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            )}
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mt-1">{selectedTopic?.title}</h5>
              <div className="layout-actions mb-1">
                {!isMobile() && (
                  <Button
                    variant="light"
                    size="sm"
                    className="me-2"
                    onClick={() => setShowContents(!showContents)}
                  >
                    {showContents ? (
                      <FontAwesomeIcon icon={faRectangleList} />
                    ) : (
                      <FontAwesomeIcon icon={faRectangleListOff} />
                    )}
                  </Button>
                )}
                <Button
                  variant="light"
                  size="sm"
                  onClick={() => {
                    setShowNerdi(!showNerdi);
                  }}
                >
                  {showNerdi ? (
                    <FontAwesomeIcon icon={faMessage} />
                  ) : (
                    <FontAwesomeIcon icon={faMessageOff} />
                  )}
                </Button>
              </div>
            </div>
            <div className="description-container">
              <h5 className="mt-4">Overview</h5>
              <p className="chapter-description">
                <HyperlinkParsedText text={selectedTopic?.description} />
              </p>
            </div>
          </Col>
          {showNerdi && (
            <Col xs={12} md={3} lg={4} className="ask-column">
              <div className="ask-heading">
                <h5>
                  <FontAwesomeIcon icon={faLightbulb} /> Have questions? Just
                  Ask.
                </h5>

                <p>
                  <small>
                    Ask any question that comes to your mind during video.
                  </small>
                </p>
              </div>
              <div className="ask-column-layout">
                <div className="w-100 messages-list">
                  <div className="chat-text-container">
                    <div>
                      <img
                        className="nerdi-avatar"
                        src="/nerdi.png"
                        alt="bot-logo"
                      />
                    </div>
                    <div className="chat-text">
                      <small className="text-muted">
                        <strong className="name">Nerdi</strong>
                      </small>
                      <div className="message">
                        Hey 👋 <br />
                        Nice to meet you. I am Nerdi
                        <br /> <strong>{course.creator.name}</strong>'s
                        assistant. Feel free to ask me any question you might
                        have during your course here.
                        <br />
                        As if as if you were taking a live session.
                      </div>
                    </div>
                  </div>

                  {chatMessages.map((message, index) => (
                    <div
                      className={`chat-text-container ${
                        message.role === "assistant" ? "bot" : "user"
                      }`}
                      key={index}
                    >
                      <div>
                        <img
                          className="nerdi-avatar"
                          src={
                            message.role === "user"
                              ? (currentUser?.profile_picture ??
                                "/default-profile-pic.png")
                              : "/nerdi.png"
                          }
                          alt="bot-logo"
                        />
                      </div>
                      <div className="chat-text">
                        <small className="text-muted">
                          <strong className="name">
                            {message.role === "assistant" ? "Nerdi" : "You"}
                          </strong>
                        </small>
                        <div className="message">
                          <ReactMarkdown>{message.message}</ReactMarkdown>
                        </div>
                      </div>
                    </div>
                  ))}
                  {isAskLLMLoading && (
                    <div className={`chat-text-container bot`}>
                      <div>
                        <img
                          className="nerdi-avatar"
                          src="/nerdi.png"
                          alt="bot-logo"
                        />
                      </div>
                      <div className="chat-text">
                        <small className="text-muted">
                          <strong className="name">Nerdi</strong>
                        </small>
                        <div className="message">&middot;&middot;&middot;</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-100">
                <Form.Group className="ask-text-box">
                  <FormControl
                    type="text"
                    as="textarea"
                    placeholder="Ask a question, as if you were taking this course live"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault(); // Prevents the newline
                        handleAskQuestion(); // Call the ask question function
                      }
                    }}
                    disabled={isAskLLMLoading}
                  />
                  <Button
                    variant="dark ms-1"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAskQuestion();
                    }}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </Button>
                </Form.Group>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default ViewCourse;
