import { faChevronRight, faListOl } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../shared/styles/NewSublistItem.css";

function TopicItem({ topic, questionsCount, onClick }) {
  return (
    <div
      className="d-flex justify-content-between align-items-center chapter-item"
      onClick={onClick}
    >
      <div className="d-flex">
        <div className="chapter-icon">
          <FontAwesomeIcon icon={faListOl} size="lg" />
        </div>
        <div>
          <h5>{topic}</h5>
          <p className="text-muted chapter-stats">
            {questionsCount} {questionsCount === 1 ? "question" : "questions"}
          </p>
        </div>
      </div>
      <div>
        <FontAwesomeIcon icon={faChevronRight} size="lg" />
      </div>
    </div>
  );
}

export default TopicItem;
