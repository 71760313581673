import { useFormik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useLoginMutation, useResendActivationMutation } from "../redux/api";
import { loginSuccess } from "../redux/auth";
import * as Yup from "yup";
import toast from "react-hot-toast";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

function SignInForm({
  onSignIn,
  isModalView = false,
  role = "course_creator",
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [login, { isLoading, isError, error }] = useLoginMutation();
  const [resendActivation] = useResendActivationMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        // Step 1: Login and get the tokens
        const loginResponse = await login({
          email: values.email,
          password: values.password,
        }).unwrap();
        const { access, refresh, role } = loginResponse;

        // Step 2: Dispatch loginSuccess to store tokens in Redux and localStorage
        dispatch(
          loginSuccess({ accessToken: access, refreshToken: refresh, role }),
        );

        onSignIn?.(role);
      } catch (error) {
        console.error("Login or fetch user failed", error);
      }
      setSubmitting(false);
    },
  });

  const handleResendActivation = async () => {
    await resendActivation({
      email: formik.values.email,
    });
    toast.success("Activation email sent successfully!");
  };

  const showResendActivationEmail =
    error?.data?.detail === "User is inactive" ||
    error?.data?.non_field_errors?.some(
      (error) => error === "User is inactive",
    );

  return (
    <Form className="signin-form" onSubmit={formik.handleSubmit}>
      {!isModalView && (
        <>
          <h1>Sign in</h1>
          <div className="w-100 d-flex justify-content-center flex-column align-items-center">
            {/* <Logo /> */}
          </div>
        </>
      )}
      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          name="email"
          placeholder="john.doe@example.com"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={!!formik.errors.email && formik.touched.email}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.email}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          name="password"
          placeholder="Password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={!!formik.errors.password && formik.touched.password}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.password}
        </Form.Control.Feedback>
      </Form.Group>
      {isError && (
        <>
          <Alert variant="danger" className="mb-0">
            {error?.data?.detail && `Error: ${error?.data?.detail}`}
            {error?.data?.non_field_errors &&
              `Error: ${error?.data.non_field_errors.map((error) => error).join(". ")}`}
          </Alert>
          {showResendActivationEmail && (
            <small
              className="btn-link"
              role="button"
              onClick={handleResendActivation}
            >
              Resend activation email
            </small>
          )}
        </>
      )}
      <div>
        <Button type="submit" disabled={formik.isSubmitting || isLoading}>
          Sign in
        </Button>
      </div>
      <div>
        Don't have an account?{" "}
        <Link to={`/signup?from=${location.pathname}&role=${role}`}>
          Sign up
        </Link>
      </div>
    </Form>
  );
}

export default SignInForm;
