import { useFormik } from "formik";
import { useState } from "react";
import { Alert, Button, Col, Form, FormGroup, Image, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useFetchUserQuery, useUpdateUserInformationMutation, useUpdateUserPasswordMutation } from '../../redux/api';
import { selectCurrentUser } from "../../redux/auth";
import "./Settings.css";


const profilePictureValidationSchema = Yup.object({
  profilePicture: Yup.mixed()
    .required("Profile picture is required"),
});

const informationValidationSchema = Yup.object({
  firstname: Yup.string()
    .required("First name is required"),
  lastname: Yup.string()
    .required("Last name is required"),
});

const passwordValidationSchema = Yup.object({
  currentPassword: Yup.string()
    .required("Password is required"),
  newPassword: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

function Settings() {
  useFetchUserQuery();
  const currentUser = useSelector(selectCurrentUser);
  const [profilePicture, setProfilePicture] = useState();

  const userId = useSelector(state => state?.auth?.user?.id)
  const [updateUserProfilePicture, { isLoading: isLoadingProfilePicture, isError: isProfilePictureError, error: errorProfilePicture }] = useUpdateUserInformationMutation();
  const [updateUserInformation, { isLoading, isError, error }] = useUpdateUserInformationMutation();
  const [updateUserPassword, { isLoading: isLoadingPassword, error: errorPassword }] = useUpdateUserPasswordMutation();

  const formikProfilePicture = useFormik({
    initialValues: {
      profilePicture: currentUser?.profile_picture,
    },
    validationSchema: profilePictureValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const formData = new FormData();
        formData.append("profile_picture", values.profilePicture);
        await updateUserProfilePicture({
          userData: formData,
          userId,
        }).unwrap();
        toast.success("Profile picture updated successfully")

      } catch (err) {
        console.error("Profile picture update failed:", err);
      }
      setSubmitting(false);
    },
  });

  const formikInformation = useFormik({
    initialValues: {
      firstname: currentUser?.first_name,
      lastname: currentUser?.last_name,
      bio: currentUser?.bio,
    },
    validationSchema: informationValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {

        await updateUserInformation({
          userData: {
            first_name: values.firstname,
            last_name: values.lastname,
            bio: values.bio,
          },
          userId,
        }).unwrap();
        toast.success("Information updated successfully")

      } catch (err) {
        console.error("Sign-up failed:", err);
      }
      setSubmitting(false);
    },
  });

  const formikPassword = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
    },
    validationSchema: passwordValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {

        await updateUserPassword({
          current_password: values.currentPassword,
          new_password: values.newPassword,
        }).unwrap();
        toast.success("Password updated successfully")
      } catch (err) {
        console.error("Sign-up failed:", err);
      }
      setSubmitting(false);
    },
  });

  Object.entries(errorPassword?.data ?? {}).map(([k, v]) => {
    return `Error: ${v}`;
  })


  return (
    <Row className="d-flex justify-content-center flex-column align-items-center">
      <Col xl={5} lg={6} md={8} sm={12} className="mt-5">
        <Form className="settings-form" onSubmit={formikProfilePicture.handleSubmit}>
          <FormGroup>
            <h3>
              Profile picture
            </h3>
            <Image src={profilePicture ?? currentUser?.profile_picture ?? "/default-profile-pic.png"} rounded className="settings-profile-pic" />
            <Form.Label>
              Update profile picture
            </Form.Label>
            <Form.Control
              type="file"
              name="profilePicture"
              onChange={(event) => {
                const url = URL.createObjectURL(event.currentTarget.files[0]);
                setProfilePicture(url);
                formikProfilePicture.setFieldValue("profilePicture", event.currentTarget.files[0]);
              }}
              isInvalid={!!formikProfilePicture.errors.profilePicture && formikProfilePicture.touched.profilePicture}
              onBlur={formikProfilePicture.handleBlur}
            />
            <Form.Control.Feedback type="invalid">
              {formikProfilePicture.errors.profilePicture}
            </Form.Control.Feedback>
          </FormGroup>
          {isProfilePictureError && (
            <Alert variant="danger">
              {Object.entries(errorProfilePicture?.data ?? {}).map(([k, v]) => {
                return `Error: ${v}`;
              })}
            </Alert>
          )}
          <div className="justify-content-end w-100 d-flex">
            <Button type="submit" variant="primary" disabled={isLoadingProfilePicture}>
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </div>
        </Form>
        <hr />
        <Form id="information" className="settings-form mt-5" onSubmit={formikInformation.handleSubmit}>
          <h3>Information</h3>
          <FormGroup>
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder={currentUser?.email} disabled />
          </FormGroup>
          <FormGroup>
            <Form.Label>First Name</Form.Label>
            <Form.Control type="text" name="firstname" placeholder="John Doe"
              isInvalid={formikInformation.touched.firstname && !!formikInformation.errors.firstname}
              onChange={formikInformation.handleChange}
              onBlur={formikInformation.handleBlur}
              value={formikInformation.values.firstname}

            />
            <Form.Control.Feedback type="invalid">
              {formikInformation.errors.firstname}
            </Form.Control.Feedback>
          </FormGroup>
          <FormGroup>
            <Form.Label>Last Name</Form.Label>
            <Form.Control type="text" placeholder="John Doe" name="lastname"
              isInvalid={formikInformation.touched.lastname && !!formikInformation.errors.lastname}
              onChange={formikInformation.handleChange}
              onBlur={formikInformation.handleBlur}
              value={formikInformation.values.lastname}
            />
            <Form.Control.Feedback type="invalid">
              {formikInformation.errors.lastname}
            </Form.Control.Feedback>
          </FormGroup>
          <FormGroup>
            <Form.Label>Bio</Form.Label>
            <Form.Control type="text" as="textarea" placeholder="Something about yourself..." name="bio"
              isInvalid={formikInformation.touched.bio && !!formikInformation.errors.bio}
              onChange={formikInformation.handleChange}
              onBlur={formikInformation.handleBlur}
              value={formikInformation.values.bio}
            />
            <Form.Control.Feedback type="invalid">
              {formikInformation.errors.bio}
            </Form.Control.Feedback>
          </FormGroup>
          {isError && (
            <Alert variant="danger">
              {Object.entries(error?.data ?? {}).map(([k, v]) => {
                return `Error: ${v}`;
              })}
            </Alert>
          )}
          <div className="justify-content-end w-100 d-flex">
            <Button type="submit" variant="primary" disabled={isLoading}>
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </div>
        </Form>
        <hr />
        <Form className="settings-form mt-5" onSubmit={formikPassword.handleSubmit}>
          <h3>Reset Password</h3>
          <FormGroup>
            <Form.Label>Current password</Form.Label>
            <Form.Control type="password" name="currentPassword" placeholder="Current Password"
              isInvalid={formikPassword.touched.currentPassword && !!formikPassword.errors.currentPassword}
              onChange={formikPassword.handleChange}
              onBlur={formikPassword.handleBlur} />
            <Form.Control.Feedback type="invalid">
              {formikPassword.errors.currentPassword}
            </Form.Control.Feedback>
          </FormGroup>

          <FormGroup>
            <Form.Label>New password</Form.Label>
            <Form.Control type="password" placeholder="New Password" name="newPassword"
              isInvalid={formikPassword.touched.newPassword && !!formikPassword.errors.newPassword}
              onChange={formikPassword.handleChange}
              onBlur={formikPassword.handleBlur} />
            <Form.Control.Feedback type="invalid">
              {formikPassword.errors.newPassword}
            </Form.Control.Feedback>
          </FormGroup>
          {errorPassword && (
            <Alert variant="danger">
              {Object.entries(errorPassword?.data ?? {}).map(([k, v]) => {
                return `Error: ${v}`;
              })}
            </Alert>
          )}
          <div className="justify-content-end w-100 d-flex">
            <Button type="submit" variant="primary" disabled={isLoading}>
              {isLoadingPassword ? "Updating..." : "Update"}
            </Button>
          </div>
        </Form>
      </Col>
    </Row >
  );
}

export default Settings;