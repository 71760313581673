import { Accordion, Button } from "react-bootstrap";

const Questions = ({ questions = [], onDelete, onEdit }) => {
  return questions.length > 0 ? (
    <Accordion>
      {questions.map((question, questionIndex) => (
        <Accordion.Item key={questionIndex} eventKey={questionIndex.toString()}>
          <Accordion.Header>{question.question_text}</Accordion.Header>
          <Accordion.Body>
            <ul>
              {question.answers?.map((answer, answerIndex) => (
                <li key={answerIndex}>
                  {answer.answer_text} {answer.is_correct ? "(Correct)" : ""}
                </li>
              ))}
            </ul>
            <div className="d-flex justify-content-end">
              <Button variant="link" onClick={() => onEdit(questionIndex)}>
                Edit
              </Button>

              <Button variant="danger" onClick={() => onDelete(questionIndex)}>
                Delete
              </Button>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  ) : null;
};

export default Questions;
