import { useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useParams } from "react-router-dom";
import { useGetEnrollmentsQuery } from "../../../redux/api";

function Enrollments() {
  const { courseId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");

  const { data: enrollments, isLoading } = useGetEnrollmentsQuery(courseId);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  if (isLoading) return <Spinner />;

  console.log(enrollments);

  const data = enrollments.filter((d) => {
    return (
      d.student.name.includes(searchTerm) ||
      d.student.email.includes(searchTerm)
    );
  });

  return (
    <Row>
      <Col sm={12}>
        <Col sm={12} lg={4}>
          <h2>Enrollments</h2>
          <Form.Group className="mb-3">
            <Form.Label>Search</Form.Label>
            <Form.Control
              value={searchTerm}
              type="text"
              placeholder="Search by name or email"
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, i) => (
              <tr>
                <td>{d.student.name}</td>
                <td>{d.student.email}</td>
                <td>{d.enrolled_at}</td>
              </tr>
            ))}
            {data.length === 0 && (
              <tr>
                <td colSpan="3">
                  <p className="text-center">No enrollments.</p>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

export default Enrollments;
