import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCreateChapterMutation } from "../../../redux/api";
import "../Chapter.css";
import ChapterInfoForm from "../ChapterForm";
import validationSchema from "../validationSchema";

const NewChapter = () => {
  const navigate = useNavigate();
  const [createChapter, { isLoading }] = useCreateChapterMutation();
  let { courseId } = useParams();

  const handleSubmitChapter = async () => {
    try {
      const createdChapter = await createChapter({
        title: formik.values.title,
        description: formik.values.description,
        hidden: formik.values.hidden,
        course: courseId,
      }).unwrap();
      return createdChapter;
    } catch (err) {
      console.error("Failed to create chapter:", err);
      toast.error("Something went wrong.");
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      hidden: false,
    },
    validationSchema,
    onSubmit: handleSubmitChapter,
  });

  const handleAddNewTopic = () => {
    formik.submitForm().then((createdChapter) => {
      if (createdChapter) {
        navigate(
          `/app/course/${courseId}/chapter/${createdChapter.id}/topic/add`,
        );
      }
    });
  };

  const handleSave = () => {
    formik.submitForm().then((createdChapter) => {
      if (createdChapter) {
        navigate(`/app/course/${courseId}/chapter/${createdChapter.id}/edit`);
        toast.success("Chapter added successfully!");
      }
    });
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => navigate(`/app/course/${courseId}/edit`)}
        >
          Course
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Chapter</Breadcrumb.Item>
      </Breadcrumb>
      <Row className="w-100 mb-5">
        <Col sm={12}>
          <ChapterInfoForm formik={formik} />
          <div
            className="d-flex form-container add-new-chapter mt-3"
            onClick={handleAddNewTopic}
          >
            <FontAwesomeIcon className="m-2" icon={faPlus} />
            Add a new topic
          </div>

          <div className="form-container d-flex justify-content-between mt-3">
            <Button
              variant="light"
              as={Link}
              to={`/app/course/${courseId}/edit`}
            >
              <FontAwesomeIcon icon={faChevronLeft} /> Back
            </Button>
            <Button type="button" onClick={handleSave} disabled={isLoading}>
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NewChapter;
