import {
  useLazyGetPresignedUrlQuery,
  useUploadFileMutation,
} from "../../redux/api";

const useStorageUpload = () => {
  const [getPresignedUrl, { isLoading: isGettingPresignedUrl }] =
    useLazyGetPresignedUrlQuery();

  const [uploadFile, { isLoading: isUploadingFile }] = useUploadFileMutation();

  const uploadFileToStorage = async (file) => {
    const data = await getPresignedUrl({
      fileName: file.name,
    }).unwrap();
    if (data.presigned_url) {
      await uploadFile({
        presignedUrl: data.presigned_url,
        file,
      }).unwrap();
    }
  };

  return {
    uploadFileToStorage,
    isLoading: isGettingPresignedUrl || isUploadingFile,
  };
};

export default useStorageUpload;
