import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Logo from "../shared/components/Logo";
import "./Signin.css";
import SignInForm from "./SignInForm";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

// Yup validation schema

function Signin() {
  const navigate = useNavigate();
  const handleOnSignIn = (role) => {
    if (role === "student") {
      toast.success(
        "As a student, directly go to the course page and sign in from there!",
      );
    } else {
      navigate("/app/dashboard");
    }
  };
  return (
    <Container
      fluid
      className="d-flex flex-column justify-content-center align-items-center signin-form-container"
    >
      <Row className="w-100">
        <Col
          sm={12}
          className="d-flex justify-content-center flex-column align-items-center"
        >
          <div className="w-100 d-flex justify-content-center flex-column align-items-center">
            <Logo otherClasses="text-primary" />
          </div>
          <Card className="form-container mt-4">
            <SignInForm onSignIn={handleOnSignIn} />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Signin;
