import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { Breadcrumb, Col, Row, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useDeleteChapterMutation,
  useGetChapterQuery,
  useReorderTopicsMutation, // Add this to handle topic reordering
  useUpdateChapterMutation,
} from "../../../redux/api";
import { StrictModeDroppable } from "../../../shared/components/StrictModeDroppable"; // You can reuse this
import "../Chapter.css";
import ChapterInfoForm from "../ChapterForm";
import TopicItem from "../TopicItem";
import validationSchema from "../validationSchema";
import Dialog from "../../../shared/components/Dialog";

const NewChapter = () => {
  const navigate = useNavigate();
  const { courseId, chapterId } = useParams();
  const [updateChapter, { isLoading: isUpdateChapterLoading }] =
    useUpdateChapterMutation();
  const {
    data: chapter,
    isLoading: isGetChapterLoading,
    isSuccess: isGetChapterSuccess,
  } = useGetChapterQuery(chapterId, { refetchOnMountOrArgChange: true });

  const [reorderTopics] = useReorderTopicsMutation(); // Mutation to reorder topics
  const [deleteChapter, { isLoading: isDeleteChapterLoading }] =
    useDeleteChapterMutation();

  const [isReordering, setIsReordering] = useState(false);
  const [topicsOrder, setTopicsOrder] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (isGetChapterSuccess) {
      setTopicsOrder(chapter?.topics);
    }
  }, [isGetChapterSuccess, chapter?.topics]);

  const handleSubmitChapter = async () => {
    try {
      await updateChapter({
        id: chapterId,
        title: formik.values.title,
        description: formik.values.description,
        hidden: formik.values.hidden,
      }).unwrap();
      if (isReordering) {
        saveReordering();
      }
    } catch (err) {
      console.error("Failed to update chapter:", err);
      toast.error("Something went wrong.");
    }
  };

  const formik = useFormik({
    initialValues: {
      title: chapter?.title ?? "",
      description: chapter?.description ?? "",
      hidden: chapter?.hidden ?? false,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmitChapter,
  });

  const handleAddNewTopic = () => {
    formik.submitForm();
    navigate(`/app/course/${courseId}/chapter/${chapterId}/topic/add`);
  };

  const handleReorderClick = () => {
    setIsReordering(!isReordering);
  };

  const saveReordering = async () => {
    try {
      const reorderedTopics = topicsOrder.map((topic, index) => ({
        id: topic.id,
        ordering: index, // Update the ordering field
      }));

      await reorderTopics({ chapterId, topics: reorderedTopics }).unwrap(); // Call mutation to save the new order
      toast.success("Topics reordered successfully!");
      setIsReordering(false);
    } catch (err) {
      console.error("Failed to reorder topics:", err);
      toast.error("Failed to reorder topics.");
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const newOrder = Array.from(topicsOrder);
    const [movedTopic] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedTopic);

    setTopicsOrder(newOrder);
  };

  const handleDelete = async () => {
    try {
      await deleteChapter(chapterId).unwrap();
      toast.success("Chapter deleted successfully!");
      navigate(`/app/course/${courseId}/edit`);
    } catch (err) {
      toast.error("Failed to delete course.");
    }
  };

  if (isGetChapterLoading) return <Spinner />;

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => navigate(`/app/course/${courseId}/edit`)}
        >
          Course
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Chapter</Breadcrumb.Item>
      </Breadcrumb>
      <Row className="w-100 mb-5">
        <Col sm={12}>
          <ChapterInfoForm formik={formik} />
          <div className="form-container section">
            <div className="d-flex justify-content-between">
              <h4>Topics</h4>
              <Button
                variant="link"
                onClick={isReordering ? saveReordering : handleReorderClick}
                className="text-end p-0"
              >
                {isReordering ? "Save" : "Reorder"}
              </Button>
            </div>
            <div className="mt-3">
              <DragDropContext onDragEnd={handleDragEnd}>
                <StrictModeDroppable droppableId="topics">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {topicsOrder.length > 0
                        ? topicsOrder.map((topic, index) => (
                            <Draggable
                              key={topic.id}
                              draggableId={String(topic.id)}
                              index={index}
                              isDragDisabled={!isReordering}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <TopicItem
                                    topic={topic.title}
                                    questionsCount={
                                      topic.questions?.length ?? 0
                                    }
                                    onClick={() => {
                                      if (!isReordering) {
                                        formik.submitForm();
                                        navigate(
                                          `/app/course/${courseId}/chapter/${chapterId}/topic/${topic.id}/edit`,
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))
                        : null}
                      {provided.placeholder}
                    </div>
                  )}
                </StrictModeDroppable>
              </DragDropContext>
            </div>
            <div className="add-new-chapter" onClick={handleAddNewTopic}>
              <FontAwesomeIcon className="m-2" icon={faPlus} />
              Add a {topicsOrder.length > 0 ? "new" : ""} topic
            </div>
          </div>

          <div className="form-container d-flex justify-content-between align-items-center mt-3">
            <Button
              variant="light"
              as={Link}
              to={`/app/course/${courseId}/edit`}
            >
              <FontAwesomeIcon icon={faChevronLeft} /> Back
            </Button>
            <div className="mt-0">
              <Button
                className="text-danger"
                variant="link"
                type="button"
                onClick={() => {
                  setShowDeleteModal(true);
                }}
                disabled={isDeleteChapterLoading}
              >
                Delete
              </Button>
              <Button
                type="button"
                onClick={() => {
                  formik
                    .submitForm()
                    .then(() => toast.success("Chapter updated successfully!"));
                }}
                disabled={isUpdateChapterLoading}
              >
                Save
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Dialog
        open={showDeleteModal}
        onConfirm={handleDelete}
        onClose={() => setShowDeleteModal(false)}
        loading={isDeleteChapterLoading}
        title="Confirm Delete"
        body="Are you sure you want to delete this chapter? This action cannot be undone."
        confirmText="Delete"
      />
    </>
  );
};

export default NewChapter;
