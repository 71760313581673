// ActivationScreen.js

import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useActivateUserMutation } from "../redux/api";
import { Container, Spinner } from "react-bootstrap";
import Logo from "../shared/components/Logo";

function Activation() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const uid = searchParams.get("uid");
  const token = searchParams.get("token");

  const [activateUser, { isLoading, isSuccess, isError }] =
    useActivateUserMutation();

  useEffect(() => {
    if (uid && token) {
      activateUser({ uid, token });
    }
  }, [uid, token, activateUser]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Account activated successfully! Please log in.");
      navigate("/signin"); // Redirect to login page after successful activation
    } else if (isError) {
      toast.error("Failed to activate account.");
      navigate("/signin"); // Redirect to login page after successful activation
    }
  }, [isSuccess, isError, navigate]);

  return (
    <Container
      fluid
      className="d-flex flex-column justify-content-center align-items-center signin-form-container"
    >
      <Logo otherClasses="text-primary" />
      {isLoading && (
        <div className="d-flex align-items-center gap-3">
          <Spinner />
          Activation in progress...
        </div>
      )}
    </Container>
  );
}

export default Activation;
