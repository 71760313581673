import { useState } from "react";
import { Button, Card, CardBody, Col, Row, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteCourseMutation,
  useGetCourseQuery,
  useUpdateCourseMutation,
} from "../../../../redux/api";
import Dialog from "../../../../shared/components/Dialog";
import toast from "react-hot-toast";

function CourseSettings() {
  const navigate = useNavigate();
  const { courseId } = useParams();

  const { data: course, isLoading } = useGetCourseQuery(courseId);
  const [updateCourse, { isLoading: isUpdateCourseLoading }] =
    useUpdateCourseMutation();

  const [deleteCourse, { isLoading: isDeleteCourseLoading }] =
    useDeleteCourseMutation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);

  if (isLoading) return <Spinner />;

  const handlePublish = async () => {
    await updateCourse({
      courseId,
      courseData: {
        is_published: true,
      },
    }).unwrap();
    toast.success("Course published successfully!");
    setShowPublishModal(false);
  };

  const handleUnpublish = async () => {
    await updateCourse({
      courseId,
      courseData: {
        is_published: false,
      },
    }).unwrap();
    toast.success("Course unpublished successfully!");
    setShowPublishModal(false);
  };

  const handleDelete = async () => {
    await deleteCourse(courseId).unwrap();
    toast.success("Course deleted successfully!");
    setShowDeleteModal(false); // Close modal after deletion
    navigate("/app/dashboard");
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handlePublishClick = () => {
    setShowPublishModal(true);
  };

  return (
    <Row>
      <Col sm={12}>
        <Col sm={12} lg={4}>
          <h2>Settings</h2>
        </Col>
        <div className="section">
          {course.is_published ? (
            <Card>
              <Card.Header>
                <h4>Your course is public</h4>
              </Card.Header>
              <CardBody className="d-flex justify-content-between align-items-center">
                <span>
                  If you want to block access to your course remove access by
                  unpublishing it
                </span>
                <Button
                  onClick={handlePublishClick}
                  disabled={isUpdateCourseLoading}
                >
                  Unpublish
                </Button>
              </CardBody>
            </Card>
          ) : (
            <Card>
              <Card.Header>
                <h4>Publish your course</h4>
              </Card.Header>
              <CardBody className="d-flex justify-content-between align-items-center">
                <span>Make your course public so people can enroll</span>
                <Button
                  onClick={handlePublishClick}
                  disabled={isUpdateCourseLoading}
                >
                  Publish
                </Button>
              </CardBody>
            </Card>
          )}
        </div>
        <div className="section">
          <Card>
            <Card.Header>
              <h4>Delete course</h4>
            </Card.Header>
            <CardBody className="d-flex justify-content-between align-items-center">
              <span>Be very careful when performing this action</span>
              <Button
                variant="danger"
                onClick={handleDeleteClick}
                disabled={isDeleteCourseLoading}
              >
                Delete
              </Button>
            </CardBody>
          </Card>
        </div>
      </Col>

      <Dialog
        open={showDeleteModal}
        onConfirm={handleDelete}
        onClose={() => setShowDeleteModal(false)}
        loading={isDeleteCourseLoading}
        title="Confirm Delete"
        body="Are you sure you want to delete this course? This action cannot be undone."
        confirmText="Delete"
      />
      <Dialog
        open={showPublishModal}
        onConfirm={course?.is_published ? handleUnpublish : handlePublish}
        onClose={() => setShowPublishModal(false)}
        loading={isUpdateCourseLoading}
        title={`Confirm ${course?.is_published ? "Unpublsh" : "Publish"}`}
        body={`${course?.is_published ? "Are you sure you want to unpublish this course? This will prevent students from viewing your course!" : "Are you sure you want to publish this course? This will allow students to start enrolling in your course."}`}
        confirmText={course?.is_published ? "Unpublish" : "Publish"}
      />
    </Row>
  );
}

export default CourseSettings;
