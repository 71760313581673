import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useGetCourseQuery,
  useReorderChaptersMutation,
  useUpdateCourseMutation,
} from "../../../redux/api";
import { StrictModeDroppable } from "../../../shared/components/StrictModeDroppable";
import ChapterItem from "../ChapterItem";
import "../Course.css";
import CourseForm from "../CourseForm";
import validationSchema from "../validationSchema";

const EditCourse = () => {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const {
    data: course,
    isLoading: isGetCourseLoading,
    isSuccess: isGetCourseSuccess,
  } = useGetCourseQuery(courseId);

  const [updateCourse, { isLoading: isUpdateCourseLoading }] =
    useUpdateCourseMutation();

  const [reorderChapters] = useReorderChaptersMutation();
  const [isReordering, setIsReordering] = useState(false);
  const [chaptersOrder, setChaptersOrder] = useState([]);

  useEffect(() => {
    if (isGetCourseSuccess) {
      setChaptersOrder(course?.chapters);
    }
  }, [isGetCourseSuccess, course?.chapters]);

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("title", formik.values.title);
      formData.append("description", formik.values.description);
      if (formik.values.cover instanceof File) {
        formData.append("cover", formik.values.cover);
      }
      await updateCourse({ courseId, courseData: formData }).unwrap();
      if (isReordering) {
        saveReordering();
      }
    } catch (err) {
      console.error("Failed to update course:", err);
      toast.error("Something went wrong.");
    }
  };

  const formik = useFormik({
    initialValues: {
      title: course?.title ?? "",
      description: course?.description ?? "",
      cover: course?.cover ?? "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleAddNewChapter = () => {
    formik.submitForm();
    navigate(`/app/course/${courseId}/chapter/add`);
  };

  const handleReorderClick = () => {
    setIsReordering(!isReordering);
  };

  const saveReordering = async () => {
    try {
      const reorderedChapters = chaptersOrder.map((chapter, index) => ({
        id: chapter.id,
        ordering: index, // Update the ordering field
      }));

      await reorderChapters({ courseId, chapters: reorderedChapters }).unwrap();
      toast.success("Chapters reordered successfully!");
      setIsReordering(false);
    } catch (err) {
      console.error("Failed to reorder chapters:", err);
      toast.error("Failed to reorder chapters.");
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const newOrder = Array.from(chaptersOrder);
    const [movedChapter] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedChapter);

    setChaptersOrder(newOrder);
  };

  if (isGetCourseLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Row>
        <Col sm={12} md={10} className="d-flex flex-column">
          <h2>Edit Course</h2>
          <CourseForm hideHeading formik={formik} isEdit />
          <div className="section form-container">
            <div className="d-flex justify-content-between">
              <h4>Chapters</h4>
              <Button
                variant="link"
                onClick={isReordering ? saveReordering : handleReorderClick}
                className="text-end p-0"
              >
                {isReordering ? "Save" : "Reorder"}
              </Button>
            </div>
            <div className="mt-3">
              <DragDropContext onDragEnd={handleDragEnd}>
                <StrictModeDroppable droppableId="chapters">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {chaptersOrder.length > 0
                        ? chaptersOrder.map((chapter, index) => (
                            <Draggable
                              key={chapter.id}
                              draggableId={String(chapter.id)}
                              index={index}
                              isDragDisabled={!isReordering}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <ChapterItem
                                    chapterTitle={chapter.title}
                                    topicsCount={chapter.topics.length}
                                    questionsCount={chapter.topics.reduce(
                                      (acc, topic) =>
                                        acc + topic.questions.length,
                                      0,
                                    )}
                                    onClick={() => {
                                      if (!isReordering) {
                                        formik.submitForm();
                                        navigate(
                                          `/app/course/${courseId}/chapter/${chapter.id}/edit`,
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))
                        : null}
                      {provided.placeholder}
                    </div>
                  )}
                </StrictModeDroppable>
              </DragDropContext>
            </div>
            <div className="add-new-chapter" onClick={handleAddNewChapter}>
              <FontAwesomeIcon className="m-2" icon={faPlus} />
              Add a {chaptersOrder.length > 0 ? "new" : ""} chapter
            </div>
          </div>
          <div className="d-flex form-container justify-content-between section">
            <Button variant="light" as={Link} to="/app/dashboard">
              <FontAwesomeIcon icon={faChevronLeft} /> Back
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                formik
                  .submitForm()
                  .then(() => toast.success("Course updated successfully!"));
              }}
              disabled={isUpdateCourseLoading}
            >
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default EditCourse;
