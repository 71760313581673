import { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

const Dialog = ({ open, onClose, onConfirm, loading, title, body, confirmText, confirmVariant = "danger", size = undefined }) => {
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    setIsOpen(open);
  }, [open]);
  
  const handleClose = () => {
    setIsOpen(false);
    onClose?.();
  }

  return (
    <Modal show={isOpen} onHide={handleClose} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant={confirmVariant} onClick={onConfirm} disabled={loading}>
          {loading ? <Spinner size="sm" animation="border" /> : confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Dialog;
