// apiSlice.js

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import dayjs from "dayjs";
import { loginSuccess, logout } from "./auth";
import Cookies from "js-cookie";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASEURL,
  prepareHeaders: (headers, api) => {
    const token = Cookies.get("accessToken");
    if (token && api.endpoint !== "uploadFile") {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // Wait for any pending mutex operation to finish
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        Cookies.remove("accessToken");
        const refreshResult = await baseQuery(
          {
            url: "/auth/refresh/",
            method: "POST",
            body: { refresh: Cookies.get("refreshToken") },
          },
          api,
          extraOptions,
        );

        if (refreshResult.data) {
          // Store the new access token
          const newAccessToken = refreshResult.data.access;
          const newRefreshToken = refreshResult.data.refresh;

          // Dispatch the loginSuccess action to update the access token in the Redux state
          api.dispatch(
            loginSuccess({
              accessToken: newAccessToken,
              refreshToken: newRefreshToken,
            }),
          );

          // Retry the original query with the new access token
          result = await baseQuery(args, api, extraOptions);
        } else {
          // If refresh fails, log out the user
          api.dispatch(logout());
          Cookies.remove("accessToken");
          Cookies.remove("refreshToken");
        }
      } finally {
        // Release the mutex lock
        release();
      }
    } else {
      // Wait for mutex to be unlocked and retry the original request
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

// Define your API service
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Courses",
    "Course",
    "Chapters",
    "Chapter",
    "Topics",
    "Topic",
    "User",
    "Enrollments",
  ],
  endpoints: (builder) => ({
    askLLMAfterClass: builder.mutation({
      query: (data) => ({
        url: "/ask-llm-after-class/",
        method: "POST",
        body: data,
      }),
    }),
    askLLMDuringVideo: builder.mutation({
      query: (data) => ({
        url: "/ask-llm-during-video/",
        method: "POST",
        body: data,
      }),
    }),
    generateCourseDescription: builder.mutation({
      query: (data) => ({
        url: "/generate-course-description/",
        method: "POST",
        body: data,
      }),
    }),

    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/login/",
        method: "POST",
        body: credentials,
      }),
    }),
    fetchUser: builder.query({
      query: () => ({
        url: "/users/me/",
        method: "GET",
      }),
      providesTags: ["User"],
    }),
    signUp: builder.mutation({
      query: (userData) => ({
        url: "/users/",
        method: "POST",
        body: userData,
      }),
    }),
    activateUser: builder.mutation({
      query: ({ uid, token }) => ({
        url: `/users/activation/`,
        method: "POST",
        body: { uid, token },
      }),
    }),
    updateUserInformation: builder.mutation({
      query: ({ userData, userId }) => ({
        url: `/users/${userId}/`,
        method: "PATCH",
        body: userData,
      }),
      invalidatesTags: ["User"],
    }),
    updateUserPassword: builder.mutation({
      query: ({ current_password, new_password }) => ({
        url: `/users/set_password/`,
        method: "POST",
        body: { current_password, new_password },
      }),
    }),
    resendActivation: builder.mutation({
      query: ({ email }) => ({
        url: `/users/resend_activation/`,
        method: "POST",
        body: { email },
      }),
    }),

    createCourse: builder.mutation({
      query: (courseData) => ({
        url: "/courses/",
        method: "POST",
        body: courseData,
      }),
      invalidatesTags: ["Courses", "Course"],
    }),
    updateCourse: builder.mutation({
      query: ({ courseId, courseData }) => ({
        url: `/courses/${courseId}/`,
        method: "PATCH",
        body: courseData,
      }),
      invalidatesTags: ["Courses", "Course"],
    }),
    getCourses: builder.query({
      query: () => "/courses/", // Adjust the endpoint as per your API
      providesTags: ["Courses"],
    }),
    getCourse: builder.query({
      query: (courseId) => `/courses/${courseId}/`, // Adjust the endpoint as per your API
      providesTags: ["Course"],
      transformResponse: (response) => ({
        ...response,
        creator: {
          ...response.creator,
          name: response.creator.first_name + " " + response.creator.last_name,
        },
      }),
    }),
    getPublicCourse: builder.query({
      query: (courseId) => `/public/courses/${courseId}/`, // Adjust the endpoint as per your API
      providesTags: ["Course"],
      transformResponse: (response) => ({
        ...response,
        creator: {
          ...response.creator,
          name: response.creator.first_name + " " + response.creator.last_name,
        },
      }),
    }),
    deleteCourse: builder.mutation({
      query: (courseId) => ({
        url: `/courses/${courseId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Courses", "Course"],
    }),
    updateCourseProgress: builder.mutation({
      query: ({ courseId, topicId }) => ({
        url: `/courses/${courseId}/update-progress/`,
        method: "POST",
        body: {
          topic_id: topicId,
        },
      }),
      invalidatesTags: ["Courses", "Course"],
    }),
    reorderChapters: builder.mutation({
      query: ({ courseId, chapters }) => ({
        url: `courses/${courseId}/reorder-chapters/`,
        method: "POST",
        body: { chapters },
      }),
      invalidatesTags: ["Courses", "Course"],
    }),

    createEnrollment: builder.mutation({
      query: (courseId) => ({
        url: `/courses/${courseId}/enroll/`,
        method: "POST",
      }),
      invalidatesTags: ["Course", "Enrollments"], // Invalidate cache for the course
    }),
    getEnrollments: builder.query({
      query: (courseId) => `/courses/${courseId}/enrollments/`,
      providesTags: ["Enrollments"], // Invalidate cache for the course
      transformResponse: (response) => {
        return response.map((enrollment) => ({
          ...enrollment,
          student: {
            ...enrollment.student,
            name:
              enrollment.student.first_name +
              " " +
              enrollment.student.last_name,
          },
          enrolled_at: dayjs(enrollment.enrolled_at).format(
            "ddd, MMM MM, YYYY hh:mm A",
          ),
        }));
      },
    }),

    createChapter: builder.mutation({
      query: (chapterData) => ({
        url: "/chapters/",
        method: "POST",
        body: chapterData,
      }),
      invalidatesTags: ["Chapters", "Chapter", "Course", "Courses"],
    }),
    updateChapter: builder.mutation({
      query: (chapterData) => ({
        url: `/chapters/${chapterData.id}/`,
        method: "PATCH",
        body: chapterData,
      }),
      invalidatesTags: ["Chapters", "Chapter", "Course", "Courses"],
    }),
    getChapters: builder.query({
      query: (courseId) => `/chapters/?course=${courseId}/`,
      providesTags: ["Chapters"],
    }),
    getChapter: builder.query({
      query: (chapterId) => `/chapters/${chapterId}/`,
      providesTags: ["Chapter"],
    }),
    deleteChapter: builder.mutation({
      query: (chapterId) => ({
        url: `/chapters/${chapterId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Courses", "Course", "Chapters", "Chapter"],
    }),
    reorderTopics: builder.mutation({
      query: ({ chapterId, topics }) => ({
        url: `chapters/${chapterId}/reorder-topics/`,
        method: "POST",
        body: { topics },
      }),
      invalidatesTags: ["Course", "Chapters", "Chapter"],
    }),

    createTopic: builder.mutation({
      query: (topicData) => ({
        url: "/topics/",
        method: "POST",
        body: topicData,
      }),
      invalidatesTags: [
        "Topics",
        "Topic",
        "Chapter",
        "Chapters",
        "Course",
        "Courses",
      ],
    }),
    updateTopic: builder.mutation({
      query: ({ topicId, topicData }) => ({
        url: `/topics/${topicId}/`,
        method: "PATCH",
        body: topicData,
      }),
      invalidatesTags: [
        "Topics",
        "Topic",
        "Chapter",
        "Chapters",
        "Course",
        "Courses",
      ],
    }),
    getTopics: builder.query({
      query: (chapterId) => `/topics/?chapter=${chapterId}/`,
      providesTags: ["Topics"],
    }),
    getTopic: builder.query({
      query: (topicId) => `/topics/${topicId}/`,
      providesTags: ["Topic"],
    }),
    deleteTopic: builder.mutation({
      query: (topicId) => ({
        url: `/topics/${topicId}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "Courses",
        "Course",
        "Chapters",
        "Chapter",
        "Topics",
        "Topic",
      ],
    }),
    getChatMessages: builder.query({
      query: (topicId) => ({
        url: `/chat/messages/?topic=${topicId}`,
      }),
    }),

    getPresignedUrl: builder.query({
      query: ({ topicId, fileName }) =>
        `/shared/generate-presigned-url/?file_name=${fileName}`,
    }),
    uploadFile: builder.mutation({
      query: ({ presignedUrl, file }) => ({
        url: presignedUrl,
        method: "PUT",
        body: file,
      }),
      invalidatesTags: ["User", "Courses", "Course", "Topics", "Topic"],
    }),
  }),
});

export const {
  useLoginMutation, // Export the login hook
  useFetchUserQuery,
  useLazyFetchUserQuery,
  useSignUpMutation,
  useActivateUserMutation,
  useUpdateUserInformationMutation,
  useUpdateUserPasswordMutation,
  useResendActivationMutation,

  useCreateCourseMutation,
  useUpdateCourseMutation,
  useGetCoursesQuery,
  useLazyGetCourseQuery,
  useGetCourseQuery,
  useGetPublicCourseQuery,
  useLazyGetPublicCourseQuery,
  useDeleteCourseMutation,
  useUpdateCourseProgressMutation,
  useDeleteChapterMutation,
  useReorderChaptersMutation,

  useCreateEnrollmentMutation,
  useGetEnrollmentsQuery,

  useCreateChapterMutation,
  useUpdateChapterMutation,
  useGetChapterQuery,
  useDeleteTopicMutation,
  useReorderTopicsMutation,

  useCreateTopicMutation,
  useUpdateTopicMutation,
  useGetTopicQuery,
  useLazyGetPresignedUrlQuery,
  useUploadFileMutation,

  useAskLLMAfterClassMutation,
  useAskLLMDuringVideoMutation,
  useGetChatMessagesQuery,
  useGenerateCourseDescriptionMutation,
} = apiSlice;
