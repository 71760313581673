import React from 'react';

const HyperlinkParsedText = ({ text }) => {
  // Regular expression to find URLs in text
  const urlRegex = /(\bhttps?:\/\/[^\s]+(\.[^\s]+)*)/gi;
  const newlineRegex = /(\r\n|\r|\n)/g;

  // Function to parse and replace URLs with anchor tags, and handle newlines
  const parseText = (inputText) => {
    // Split by newline characters
    return inputText.split(newlineRegex).map((line, index) => {
      if (newlineRegex.test(line)) {
        return <br key={`newline-${index}`} />;
      }

      // Split each line further by URL regex to handle links
      return line.split(urlRegex).map((part, i) => {
        if (urlRegex.test(part)) {
          return (
            <a key={`${index}-${i}`} href={part} target="_blank" rel="noopener noreferrer">
              {part}
            </a>
          );
        }
        return part;
      });
    });
  };

  if (!text) return null;

  return <div>{parseText(text)}</div>;
};

export default HyperlinkParsedText;
